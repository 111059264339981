<template>

<div id="contact"  class="contents">
    <MessageBox ref="msgBox" />

    <h1 title="お問い合わせ">CONTACT</h1>
    <div class="intro">
      <h2>企画段階からのお手伝い</h2>
      <p>
        多くの場合、アイデア・企画段階からお客様と一緒に創り上げるケースがほとんどです。<br>
        アイデア段階でのご相談はもちろん、お見積、採用について等、まずはお気軽にご相談ください。<br>
        後日、担当よりメールにてご連絡差し上げます。</p>
    </div>

    <div class="content">

      <section class="contact-form">
        <form name="contact">
          <div class="info"><span class="required">※</span>必須項目</div>
          <div>

            <label class="type" title="contact-type">お問い合わせ種別<span class="required">※</span><br>
              <label><input v-model="formData['contact-type-1']" type="radio" name="contact-type" value="案件のご相談・ご依頼" checked>ご相談・ご依頼</label>
              <label><input v-model="formData['contact-type-1']" type="radio" name="contact-type" value="求人・採用について">求人・採用について</label>
              <label><input v-model="formData['contact-type-1']" type="radio" name="contact-type" value="その他">その他</label>
            </label>

            <label title="NAME" :class="{ error: error['-ve-name-1']}">氏名<span class="required">※</span>
            <div class="errorText" v-show="error['-ve-name-1']">{{ error['-ve-name-1'] }}</div>
            <input v-model="formData['name-1']" type="text" name="name-1" value="" class="" id="name" aria-required="true">
            </label>

            <label title="COMPANY" :class="{ error: error['-ve-company-1']}">会社名
              <input v-model="formData['company-1']" type="text" name="company-1" value="" class="" id="company" aria-required="true" placeholder="法人様の場合は入力ください">
            </label>

            <label title="E-MAIL" :class="{ error: error['-ve-email-1']}">メールアドレス<span class="required">※</span>
              <div class="errorText" v-show="error['-ve-email-1']">{{ error['-ve-email-1'] }}</div>
              <input v-model="formData['email-1']" type="email" name="email" value="" class="" id="email" aria-required="true">
            </label>

            <!-- <label title="PHONE">電話番号
              <input type="email" name="email" value="" class="" id="email" aria-required="true" placeholder="電話でのご連絡をご希望の方はください">
            </label> -->

            <label for="message" title="MESSAGE" :class="{ error: error['-ve-message-1']}">お問い合わせ内容<span class="required">※</span>
            <div class="errorText" v-show="error['-ve-message-1']">{{ error['-ve-message-1'] }}</div>
            <textarea v-model="formData['message-1']" name="message" class="" id="message" aria-required="true"></textarea>
            </label>
          </div>

          <label>個人情報の取扱いについて</label>
          <pp />
          <label :class="{ error: error['-ve-acceptance-238']}">
            <div class="errorText" v-show="error['-ve-acceptance-238']">{{ error['-ve-acceptance-238'] }}</div>
            <input v-model="formData['acceptance-238']" type="checkbox" name="acceptance-238" true-value="1" false-value="" aria-invalid="false">個人情報の取扱いについて同意する<span class="required">※</span>
          </label>

          <div class="submit-wrap">
            <!-- <input type="button" value="戻る" class="wpcf7-form-control wpcf7-back back wpcf7c-elm-step2 wpcf7c-btn-back wpcf7c-force-hide" id="back"> -->
            <input type="button" value="上記の内容で送信" id="submit" @click="submit">
            <!-- <span class="ajax-loader"></span>
            <input type="submit" value="確認" class="wpcf7-form-control wpcf7-confirm submit wpcf7c-elm-step1 wpcf7c-btn-confirm" id="confirm"> -->
          </div>
          <!-- <div class="wpcf7-response-output wpcf7-display-none"></div> -->
        </form>
      </section>

    </div>
    <ToTop />
</div>
</template>
<script>
import axios from 'axios'
import pp from '@/components/pp.vue'

export default {
  name: 'ContactForm',
  components: {
    pp
  },
  metaInfo: {
    title: 'お問い合わせ',
    bodyAttrs: {
      id: 'pg-contact'
    },
    meta: [
      { name: 'description', content: 'アイデア段階でのご相談はもちろん、お見積もり、採用に関するお問い合わせ等、まずはお気軽にご相談ください。' }
    ],
    link: [
      { rel: 'canonical', href: 'https://sorashiro.com/contact/' }
    ]
  },
  data () {
    return {
      responseData: null,
      formData: {},
      error: {}
    }
  },
  mounted: function () {
    this.formData = this.getDefaultData()

    this.$nextTick(function () {
      this.initGsap(this.$isMobile())
    })
  },
  methods: {
    clear () {
      this.$refs.form.reset()
    },
    submit () {
      const formData = this.convertJsontoUrlencoded(this.formData)
      this.$refs.msgBox.show('loading', '')
      this.error = {}

      axios
        .post('/wp-json/contact-form-7/v1/contact-forms/97/feedback', formData)
        .then(response => {
          var msgStatus
          this.responseData = response.data
          if (response.data.status === 'validation_failed') { // エラー
            for (const err of response.data.invalid_fields) {
              this.$set(this.error, err.error_id, err.message)
              // this.error[err.error_id] = err.message
            }
            msgStatus = 'error'
          } else if (response.data.status === 'acceptance_missing') { // 承認もれ
            msgStatus = 'error'
          } else if (response.data.status === 'mail_sent') { // 成功
            msgStatus = 'success'
            this.$ga.event('お問い合わせ', '送信', this.formData['contact-type-1'])
            this.formData = this.getDefaultData()
          }
          this.$refs.msgBox.show(msgStatus, this.responseData.message)
          // this.msg.message = this.responseData.message
        })
        .catch(error => {
          console.log(error)
        })
    },
    // Conver to JSON Object to application/x-www-form-urlencoded
    convertJsontoUrlencoded (obj) {
      const str = []
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          // console.log(obj[key])
          str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
        }
      }
      return str.join('&')
    },
    getDefaultData () {
      return {
        'contact-type-1': '案件のご相談・ご依頼',
        'name-1': '',
        'company-1': '',
        'email-1': '',
        'message-1': '',
        'acceptance-238': ''
      }
    }
  },
  computed: {
    baseUrl () {
      return this.$store.getters.baseUrl
    }
  },
  beforeDestroy: function () {
    this.removeGsap()
  }
}
</script>

<style lang="scss">

section.contact-form {
  display: inherit;

  form {
    @extend %white_box;
    margin: auto;
    padding: 1em 6em;
    max-width: 750px;

    .required {
      color: red;
      display: inline-block;
      margin: 0 .3em;
      vertical-align: top;
      font-size: 0.1em;
      font-weight: 100;
    }

    .info {
      display: none;
      position: absolute;
      right: 1%;
      bottom: 101%;
      text-align: right;
      font-size: 0.8em;
    }

    .error{
      .errorText{
        color: red;
        float: right;
        font-size: 0.8em;
        border-radius: 20px;
        padding: 0.1em 0.6em;
        letter-spacing: 0;
      }
      input,
      textarea{
        border-color: red!important;
      }
    }

    label {
      display: block;
      margin: 1em 0 .1em 0;
      padding: 0;
      font-weight: 500;
      letter-spacing: 2px;
      color: $color_regal_blue_approx;
    }

    .type {
      margin-bottom: 2em;
      label {
        display: inline-block;
        background: #fff;
        border: 1px solid #ddd;
        padding: .9em 0;
        text-align: center;
        font-weight: 500;
        letter-spacing: 0;
        margin: .4em 0 0 -1px;
        width: 33.32%;

        &:nth-of-type(1){
          border-radius: 6px 0 0 6px;
        }
        &:nth-of-type(2){
          border-radius: 0;
        }
        &:nth-of-type(3){
          border-radius: 0 6px 6px 0;
        }

        input{
            display: inline-block;
            vertical-align: -0.1em;
        }
      }

      // !Mobile
        @include mq-max() {
          label{
            display: block;
            width: 100%;
            margin: 0 0 -1px 0;
            text-align: left;
            padding: 1em .9em;
            input{
              display: inline-block;
              margin-right: 0.4em;
              width: auto;
            }
            &:nth-of-type(1){
              border-radius: 6px 6px 0 0;
            }
            &:nth-of-type(2){
              border-radius: 0;
            }
            &:nth-of-type(3){
              border-radius: 0 0 6px 6px;
            }
          }
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      transition: all .6s;
      border: 1px solid #ddd;
      // background: #ffffff;
      padding: .5em;
      display: block;
      width: 100%;
      margin: .1em auto 2em;
      font-size: 1em;
      border-radius: 6px;

      &:hover {
        border: 1px solid $hmd_color;
      }

      &:focus {
        border: 1px solid $hmd_color;
        background: #fff;
        box-shadow: 0 0 14px #47b91e8a;
      }
    }

    textarea {
      height: 15em;
    }

    // !Mobile
    @include mq-max() {
      padding: 0;
      width: 90%;
      margin: 60px auto;
      &:before,
      &:after{
        content: none;
      }
    }
  }

  // !Mobile
  @include mq-max() {
    width: auto;
    margin: 18px auto 0 auto;

    label {
      // font-size: .9em;
    }
  }

  h3{
    font-size: 1.1em;
    margin: 4em 0 0 0;
  }

  .pp {
    background-color: #fff;
    margin: 0;
    height: 16em;
    border: 1px solid #ddd;
    font-size: .9em;
    overflow-y: scroll;
    padding: .5em 1.5em;
    text-align: left;
    h4{
      font-size: 1.2em;
      margin: 2em 0 0 0;
    }
    p{
      line-height: 1.5em;
    }
  }
}

#submit,
.back {
  @extend %btn;
  @include mq-max() {
    width: 100%;
  }
}

.submit-wrap {
  clear: both;
  padding-top: 4em;
  text-align: center;

  @include mq-max() {
    padding-top: 3em;
  }
}

</style>
